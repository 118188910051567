import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { isPlainObject, isString } from 'lodash-es';
import { Observable, throwError } from 'rxjs';

import { API_CONFIG, CustomHttpErrorResponse, PagedApiRequestParams, PagedListApiRequestParams, PagedResponse, PagedResponseWrapper } from '@@core/models/api.model';

@Injectable({
	providedIn: 'root'
})
export class ApiService {

	parseHttpParams(item: Record<string, string | number | boolean>): HttpParams {
		let p = new HttpParams();
		for (const key in item) {
			if (Object.prototype.hasOwnProperty.call(item, key)) {
				p = p.append(key, item[key].toString());
			}
		}
		return p;
	}

	createPagedApiRequestParams(params: PagedApiRequestParams): URLSearchParams {
		const queryParams = new URLSearchParams();
		const page = params?.page ?? 1;
		const limit = params?.pageSize ?? API_CONFIG.pageSize;
		const offset = (page - 1) * limit;

		queryParams.append('offset', offset.toString());
		queryParams.append('limit', limit.toString());

		if (params?.sortBy) {
			queryParams.append('sortBy', params.sortBy);
		}

		if (params?.sortDirection) {
			queryParams.append('sortDirection', params.sortDirection);
		}

		if (params?.search) {
			queryParams.append('search', params.search);
		}

		return queryParams;
	}

	createPagedApiHttpParams(params: PagedApiRequestParams): HttpParams {
		let queryParams = new HttpParams();
		const page = params?.page ?? 1;
		const limit = params?.pageSize ?? API_CONFIG.pageSize;
		const offset = (page - 1) * limit;

		queryParams = queryParams.set('offset', offset.toString());
		queryParams = queryParams.set('limit', limit.toString());

		if (params?.sortBy) {
			queryParams = queryParams.set('sortBy', params.sortBy);
		}

		if (params?.sortDirection) {
			queryParams = queryParams.set('sortDirection', params.sortDirection);
		}

		if (params?.search) {
			queryParams = queryParams.set('search', params.search);
		}

		return queryParams;
	}

	getApiErrorMessage(httpError: HttpErrorResponse): string {
		if (httpError.status >= 400 && httpError.status < 500) {
			const customHttpError = httpError as CustomHttpErrorResponse;

			if (this.#isCustomError(customHttpError)) {
				return customHttpError.error.error.message;
			}
		}

		return httpError.message || 'An error occurred';
	}

	// Use this for infinite scroll based features
	createPagedListApiHttpParams(params: PagedListApiRequestParams): HttpParams {
		let queryParams = new HttpParams();
		const limit = params.limit;
		const offset = params.offset;

		queryParams = queryParams.set('offset', offset.toString());
		queryParams = queryParams.set('limit', limit.toString());

		if (params?.sortBy) {
			queryParams = queryParams.set('sortBy', params.sortBy);
		}

		if (params?.sortDirection) {
			queryParams = queryParams.set('sortDirection', params.sortDirection);
		}

		if (params?.search) {
			queryParams = queryParams.set('filter', params.search);
			// support old behavior too
			queryParams = queryParams.set('search', params.search);
		}

		return queryParams;
	}

	emptyPagedResponse<T>(): PagedResponseWrapper<T, PagedResponse<T>> {
		return {
			data: {
				list: [],
				total: 0,
				previous: 0,
				next: 0
			}
		} as PagedResponseWrapper<T, PagedResponse<T>>;
	}

	handleApiError(httpError: HttpErrorResponse, callback?: (message: string) => void): Observable<never> {
		const errorMessage = this.getApiErrorMessage(httpError);

		callback?.(errorMessage);

		return throwError(() => new Error(errorMessage));
	}

	#isCustomError(httpError: CustomHttpErrorResponse): boolean {
		try {
			const error = httpError.error?.error;

			return error && isPlainObject(error) && isFinite(error.code) && isString(error.message);
		} catch (e) {
			return false;
		}
	}
}
