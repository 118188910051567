// MOVED TO SHARED
import { HttpErrorResponse } from '@angular/common/http';

export const API_CONFIG = {
	pageSize: 50
};

export interface PagedApiRequestParams {
	pageSize?: number;
	page?: number;
	sortBy?: string;
	sortDirection?: '' | 'asc' | 'desc';
	search?: string;
}

export interface PagedListFilterApiRequestParams<T> {
	sortBy?: keyof T;
	sortDirection?: 'asc' | 'desc';
	limit?: number;
	offset?: number;
	search?: string;
	lastFilter?: string;
	filter: { [K in keyof T]?: Array<T[K]> };
}
// same as PagedApiRequestParams with all properties matching API on backend
export interface PagedListApiRequestParams {
	sortBy?: string;
	sortDirection?: '' | 'asc' | 'desc';
	search?: string;
	limit?: number;
	offset?: number;
}

export interface ApiResponse<T> {
	data: T;
}

export interface PagedApiResponse<T> {
	data: T[];
	page: number;
	pageSize: number;
	total: number;
}

export interface PagedResponse<T> {
	list: T[];
	next: number;
	previous: number;
	total: number;
}

export interface ResponseWrapper<T> {
	data?: T;
	error?: {
		code: number;
		message: string;
	};
}

export interface PagedResponseWrapper<T, K extends PagedResponse<T> = PagedResponse<T>> extends ResponseWrapper<K> {
	data?: K;
}

export interface CustomApiError {
	code: number;
	message: string;
}

export interface CustomHttpErrorResponse extends HttpErrorResponse {
	error: {
		error: CustomApiError;
	};
}

export interface DropdownPagedApiResponseDefaultState<T> {
	list: T[];
	next: number;
	previous: number;
	total: number;
	loading: boolean;
	error: string | object;
}

export const pagedApiResponseDefaultState = {
	list: null,
	next: null,
	previous: null,
	total: null,
	loading: false,
	error: null
} as DropdownPagedApiResponseDefaultState<any>;
